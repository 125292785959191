import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/Layout"
import Header from '../components/Header'
import Seo from "../components/Seo"

import ChartArea from '../images/icons/chart-area.svg'
import Cubes from '../images/icons/cubes.svg'
import ShippingFast from '../images/icons/shipping-fast.svg'
import ProjectDiagram from '../images/icons/project-diagram.svg'
import Tools from '../images/icons/tools.svg'
import ChartPie from '../images/icons/chart-pie.svg'
import Sliders from '../images/icons/sliders.svg'
import Robot from '../images/icons/robot.svg'
import BusinessTime from '../images/icons/business-time.svg'
import Recycle from '../images/icons/recycle.svg'
import Seedling from '../images/icons/seedling.svg'
import Globe from '../images/icons/globe.svg'
import Sirkel from '../images/icons/sirkel.svg'
import BezierCurve from '../images/icons/bezier-curve.svg'
import RTLogo from '../images/rt-logo.svg'
import ContactLauri from "../components/ContactLauri"


const OurStory = () => {
  return (
    <Layout>

      <Seo title="Our Story" />
      <Header />

      <div className="container-base">
        <div className="section-1-2-center">
          <h1 className="title-xl wel-mb-s">Our Story</h1>
          <p className="body-l">Why we started and how we are helping to innovate the construction industry.</p>
        </div>
      </div>

      <div className="wel-mb-l">
        <StaticImage
          src="../images/our-story/team.png"
          quality={100}
          formats={["auto", "webp", "avif"]}
          alt="Welement"
          loading="eager"
          placeholder="blurred"
          className="w-full"
        />
      </div>

      <div className="container-base">
        <div className="wel-mb-l">
          <div className="section-1-2-center">
            <img src={ChartArea} alt="Icon" className="h-20 mx-auto wel-mb-xs mr-4 inline-block" />
            <img src={Cubes} alt="Icon" className="h-20 mx-auto wel-mb-xs inline-block" />
            <h3 className="title-m wel-mb-s">The Context</h3>
            <p className="body-l">The building industry has been stagnant and failing to embrace innovation for too long. Megatrends like urbanization and population growth are pushing demand yet at the same time we are increasingly lacking skilled site labor.</p>
          </div>
        </div>

        <div className="wel-mb-l">
          <div className="section-1-2-center">
            <img src={ShippingFast} alt="Icon" className="h-20 mx-auto wel-mb-xs mr-4 inline-block" />
            <img src={ProjectDiagram} alt="Icon" className="h-20 mx-auto wel-mb-xs inline-block" />
            <h3 className="title-m wel-mb-s">The Start</h3>
            <p className="body-l">A couple of years ago we asked ourselves what can we as construction specialist actually do to improve the process?</p>
          </div>
        </div>

        <div className="wel-mb-l">
          <div className="section-1-2-center">
            <img src={Tools} alt="Icon" className="h-20 mx-auto wel-mb-xs mr-4 inline-block" />
            <img src={ChartPie} alt="Icon" className="h-20 mx-auto wel-mb-xs inline-block" />
            <h3 className="title-m wel-mb-s">How to Solve It?</h3>
            <p className="body-l mb-8">We knew that we can’t expect different results if we do the same thing over and over again.</p>
            <p className="body-l">And we also knew that the construction on-site cost equation is not too complicated. At the end of the day, it basically boils down to material and labor cost and let’s be honest, opting for cheaper materials usually backfires fast.</p>
          </div>
        </div>
      </div>

      <div className="bg-welement-pink">


        <div className="container-base">
          <div className="section-1-2-center wel-mb-l">
            <h2 className="title-l wel-mb-m">Our Solution</h2>
            <p className="body-l mb-8">The only thing we can actually affect is time. More specifically, how we use time and minimize the non-value adding activities.</p>
          </div>
          <div className="flex justify-start wel-mb-l">
            <div className="w-full md:w-2/3 lg:w-1/2 text-left bg-welement-white p-16 rounded">
              <div className="wel-mb-s">
                <img src={Sliders} alt="Icon" className="h-16 mx-auto wel-mb-xs mr-4 inline-block" />
                <img src={Robot} alt="Icon" className="h-16 mx-auto wel-mb-xs mr-4 inline-block" />
                <img src={BusinessTime} alt="Icon" className="h-16 mx-auto wel-mb-xs inline-block" />
              </div>

              <h2 className="title-xs mb-2">Taking Construction Offsite</h2>
              <p className="body-l">We believe the most effective way to improve construction productivity is to take as much of the work as possible off-site into controlled factory conditions where it is possible to increase the level of automation and effectively manage safety, waste, and quality by applying the tested principles of Lean Manufacturing. By applying advanced computer- aided machinery and collecting real-time data at every stage we can enable our specialist to use the available time as productively as possible, with minimal waiting times and physical energy.</p>
            </div>
          </div>
          <div className="flex justify-end wel-mb-l">
            <div className="w-full md:w-2/3 lg:w-1/2 text-left bg-welement-white p-16 rounded">
              <div className="wel-mb-s">
                <img src={Recycle} alt="Icon" className="h-16 mx-auto wel-mb-xs mr-4 inline-block" />
                <img src={Seedling} alt="Icon" className="h-16 mx-auto wel-mb-xs mr-4 inline-block" />
                <img src={Globe} alt="Icon" className="h-16 mx-auto wel-mb-xs inline-block" />
              </div>
              <h2 className="title-xs mb-2">Wood is the Future</h2>
              <p className="body-l">In addition to the process change, we believe that buildings of the future should be made out of sustainable materials and timber happens to be perfect for the job. Wood is the construction material of the future and is ideal for prefabricated panelized building solutions as well as effectively reducing the carbon footprint of construction.</p>
            </div>
          </div>

          <div className="flex justify-start wel-mb-l">
            <div className="w-full md:w-2/3 lg:w-1/2 text-left bg-welement-white p-16 rounded">
              <div className="wel-mb-s">
                <img src={BezierCurve} alt="Icon" className="h-16 mx-auto wel-mb-xs mr-4 inline-block" />
                <img src={Sirkel} alt="Icon" className="h-16 mx-auto wel-mb-xs inline-block" />
              </div>
              <h2 className="title-xs mb-2">Integrated Production Pipeline</h2>
              <p className="body-l">By harnessing the latest technology and IT systems and combining it with our knowledge of main contracting and civil engineering we set out to create the most advanced off-site manufacturing to offer our partners value without compromise.</p>
            </div>
          </div>
        </div>

      </div>

      <div className="container-base">
        <div className="section-1-2-center">
          <img src={RTLogo} alt="Welement" className="h-10 mx-auto mb-8 mt-8" />

          <p className="body-l wel-mb-m">Welement is part of Rand &amp; Tuulberg Group that has over 23 years of experience in construction, real estate development and manufacturing of construction materials.</p>

        </div>
      </div>

      <ContactLauri />

    </Layout>
  )

};

export default OurStory;
